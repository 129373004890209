import(/* webpackMode: "eager" */ "/app/node_modules/bootstrap/dist/css/bootstrap.min.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/swiper/swiper.min.css");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/css/index.css");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/scss/global.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/css/App.css");
;
import(/* webpackMode: "eager" */ "/app/src/app/ui/general/header/BrowserCache.jsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/ui/general/header/CloseButton.jsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/ui/general/header/DropdownMenu.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/ui/general/header/MenuRound.jsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/ui/general/header/PayButton.jsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/ui/general/header/SearchForm.jsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/ui/Others.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/ui/tracking/Tracking.jsx");
;
import(/* webpackMode: "eager" */ "/app/src/scss/generalClass.scss");
;
import(/* webpackMode: "eager" */ "/app/src/scss/header.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/scss/footer.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/scss/text.module.scss");
