"use client";
import {
  logoHide,
  wpHide,
  btnHide,
  backgroundMenuHide,
  hideMenu,
} from "@components/headerFunctionComponent";
import Style from "@scss/header.module.scss";
import { Icon } from "@iconify/react";
import ClientWrapper from "@/ClienteWrapper";

export default function CloseButton() {
  return (
    <ClientWrapper>
      <CloseButtonComponent />
    </ClientWrapper>
  );
}

export function CloseButtonComponent() {
  function handleClick() {
    const menuMobile = document.getElementById("menu-mobile");
    const backgrounMenu = document.getElementById("overlay");
    const menu = document.getElementById("wpMobile");
    const logoMenu = document.getElementById("logoMenu");
    const btnClose = document.getElementById("btnClose");
    const timeOut = 1000;
    const elements = document.getElementsByClassName(Style.block);
    const elementsArray = Array.from(elements);

    function hideAll() {
      logoHide(logoMenu);
      wpHide(menu);
      btnHide(btnClose);
      hideMenu(menuMobile);
      backgroundMenuHide(backgrounMenu);
      setTimeout(() => {
        elementsArray.forEach((element) => {
          //hide block
          element.classList.remove(
            Style.visibleBlockTop,
            Style.visibleBlockLeft,
            Style.visibleBlockRight,
            Style.visibleBlockBottom,
            Style.visibleBlockWhite,
            Style.visibleBlock
          );
          backgrounMenu.classList.remove(Style.bgWhite);
        });
      }, timeOut);
    }
    hideAll();
  }
  return (
    <button className={Style.btnClose} id="btnClose" onClick={handleClick} aria-label="Close button">
      <Icon icon="zondicons:close-outline" />
    </button>
  );
}
