import api from "./api";

/**
 * @typedef {Object} ImageObject
 * @property {string} image
 */

/**
 * @typedef {Object} EMUTranslation
 * @property {string} title
 */

/**
 * @typedef {Object} EMUItem
 * @property {boolean} visible
 * @property {string} url
 * @property {number} orden
 * @property {ImageObject[]|null} EMUTranslation
 * @property {ImageObject|null} image
 * */

/**
 * @typedef {Object} EMUObject
 * @property {string} url
 * @property {EMUItem[]} options
 * */

/**
 * @function
 * @returns {Promise<EMUItem[]>} */
export const getEmuFromPage = async (page = "") => {
    if(page === "/" || page.trim() === ""){
        page = "home";
    } else{
        const segments = page.split('/');
         page = segments[1];
    }
    
  
    //page = page.replace(/^\/+/g, '');
    try {
        const {data} = await api.get("/help_options_sections", {params: {url: page}})
        /** @var {EMUObject[]} list */
        let list = data["hydra:member"];
        if(list.length==0){
            const {data} = await api.get("/help_options_sections", {params: {url: 'login'}})
              list = data["hydra:member"];
        }
        return list[0].options.filter(option => option.visible !== false);
    } catch (err) {
        console.error("Error para recibir datos emu:", err);
        return [];
    }
}
