"use client";
import dynamic from "next/dynamic";
import { GoogleAnalytics, GoogleTagManager } from "@next/third-parties/google";
import { getVariables } from "@/config";
const { NODE_ENV } = getVariables();
const HubSpot = dynamic(() => import("@ui/tracking/HubSpot"), { ssr: false });
const Hotjar = dynamic(() => import("@ui/tracking/Hotjar"), { ssr: false });
const ClickCase = dynamic(() => import("@/app/ui/tracking/ClickCease"), {
  ssr: false,
});
const ReactPixel = dynamic(() => import("@ui/tracking/ReactPixel"), {
  ssr: false,
});

export default function Tracking() {
  if (NODE_ENV !== "production") {
    return <></>;
  }
  return (
    <>
      <GoogleTagManager gtmId="GTM-PQ74TSF" />
      <GoogleAnalytics gaId="G-F6XC2SJ95S" />
      <ReactPixel />
      <Hotjar hjid="2931227" />
      <HubSpot id="22544136" />
      <ClickCase />
    </>
  );
}
