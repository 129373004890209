'use client'
import "bootstrap/dist/js/bootstrap.min.js";
import styles from "@/scss/header.module.scss";
import MenuItem from "@ui/general/header/MenuItem";

export default function DropdownMenu({ title, items }) {
    return (
        <li>
            <div className="dropdown">
                <a
                    href={'/'}
                    type='button'
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    arial-label="Top menu link"
                    className={styles.subNavItem}>
                    {title}
                </a>
                <ul className={`dropdown-menu ${styles.dropSubNav}`}>
                    {items.map((item) => (
                        <MenuItem data={item} key={item.id} />
                    ))}
                </ul>
            </div>
        </li>
    );
}