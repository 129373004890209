export const hideEma = () => {
    const chatbot = getEmaElement();
    if (!chatbot) return;

    chatbot.style.display = "none";
}

export const clickEma = () => {
    const chatbot = window.document.querySelector(".widget_chat_switch_open")
    if (!chatbot) return;

    chatbot.click();
}

const getEmaElement = () => {
    const chatbot = window.document.querySelector(".widget_chat_switch")
    if (!chatbot) {
        console.warn("Chatbot not found");
        return false;
    }
    return chatbot;
}