import React from "react";
import style from '../scss/BtnChat.module.scss';
import { useApi } from "../context/ApiContext";
import { url_image } from '../api/ProyectoApi';
import Picture from "./PictureComponent";
export const BtnChatItemComponent = ({item, onClick = () => {}}) => {
    const { getTranslation } = useApi();

    return (
        <div className="d-flex align-items-center justify-content-end mb-3" onClick={onClick}>
            <p className={`${style.fzNombre} mb-0 WorkSans me-3 color-white`}>{getTranslation(item,'title')}</p>
            <div className={`${style.containerImg}`}>
                <Picture src={url_image + item.image.image} alt="Prodesa" styleGroup={`${style.imgstyle} w-auto`} />
            </div>

        </div>
    )
}