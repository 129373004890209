"use client";

import React, { useEffect} from "react";
import { useLocation } from "react-router-dom";

import ClientWrapper from "@/ClienteWrapper";

export default function BrowserCache() {
  return (
    <ClientWrapper>
      <BrowserCacheComponent />
    </ClientWrapper>
  );
}

const TIME_EXPIRE = 60000;

export function BrowserCacheComponent() {
  const location = useLocation();

  useEffect(() => {
    let now = new Date();
    let cacheTime = localStorage.getItem("cache_info");
    if (
      !localStorage.getItem("actualizo-cache") &&
      now.getTime() - cacheTime > TIME_EXPIRE
    ) {
      localStorage.setItem("intento-actualiza", 1);
      window.location.reload(true);
    }

    if (!localStorage.getItem("borrar-cache")) {
      localStorage.clear();
      localStorage.setItem("borrar-cache", 1);
    }
  }, [location.pathname]);

  return null
}
