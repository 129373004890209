'use client';
import ClientWrapper from "@/ClienteWrapper";
import BtnChatComponent from "@components/BtnChatComponent";
import { ScrollToTopButtonComponent } from "@components/ScrollToTopButtonComponent";

export default function Others() {
    return (
        <ClientWrapper>
            <ScrollToTopButtonComponent />
            <BtnChatComponent />
        </ClientWrapper>
    );
}