import { Fragment, useEffect, useState } from "react";
import style from '../scss/ScrollToTopButton.module.scss';
import { Icon } from "@iconify/react";
export function ScrollToTopButtonComponent() {
    const [isVisible, setIsVisible] = useState(false);
    const toggleVisibility = () => {
        if (window.scrollY > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    useEffect(() => {
        window.addEventListener("scroll", toggleVisibility);

        return () => {
            window.removeEventListener("scroll", toggleVisibility);
        };
    }, []);
    return (
        <Fragment>
            <div className={`${style.botnScroll}`}>
                <div className={` ${style.scroll_to_top_button} ${isVisible ? `${style.visible}` : ""}`} onClick={scrollToTop}>
                    <Icon icon="ep:arrow-right" />
                </div>
            </div>

        </Fragment>
    );
}