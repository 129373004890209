'use client'
import {
  showMenu,
  backgroundMenuShow,
  logoShow,
  wpShow,
  btnShow,
} from "@components/headerFunctionComponent";
import Style from "@scss/header.module.scss";
import { Icon } from "@iconify/react";
import ClientWrapper from "@/ClienteWrapper";
export default function MenuRound() {
  return (
    <ClientWrapper>
      <MenuRoundComponent />
    </ClientWrapper>
  );
}
export function MenuRoundComponent() {
  function handleClick() {
    const menuMobile = document.getElementById("menu-mobile");
    const backgrounMenu = document.getElementById("overlay");
    const menu = document.getElementById("wpMobile");
    const logoMenu = document.getElementById("logoMenu");
    const btnClose = document.getElementById("btnClose");
    const time = 300;
    const elements = document.getElementsByClassName(Style.block);
    const elementsArray = Array.from(elements);

    function showBlock() {
      elementsArray.sort(() => Math.random() - 0.5);
      let whiteBlockCount = 0;
      const addVisibleClassWithDelay = (element, index) => {
        setTimeout(() => {
          // 4 clases para los bloques
          if (index % 4 === 0) {
            element.classList.add(Style.visibleBlockTop);
            if (whiteBlockCount < 3) {
              element.classList.add(Style.visibleBlock);
              whiteBlockCount++;
            }
          }
          if (index % 4 === 1) {
            element.classList.add(Style.visibleBlockLeft);
          }
          if (index % 4 === 2) {
            element.classList.add(Style.visibleBlockRight);
          }
          if (index % 4 === 3) {
            element.classList.add(Style.visibleBlockBottom);
          }
          setTimeout(() => {
            element.classList.add(Style.visibleBlockWhite);
          }, 1400);
        }, index * 30);
      };
      setTimeout(() => {
        backgrounMenu.classList.add(Style.bgWhite);
      }, 1400);
      elementsArray.forEach((element, index) => {
        addVisibleClassWithDelay(element, index);
      });
    }

    backgroundMenuShow(backgrounMenu);
    showBlock();
    function runFunctionsInSequence(funcs, time) {
      if (funcs.length === 0) return;
      const [funcToRun, ...remainingFuncs] = funcs;
      setTimeout(() => {
        funcToRun();
        runFunctionsInSequence(remainingFuncs, time);
      }, time);
    }
    const menuFunctions = [
      () => showMenu(menuMobile),
      () => logoShow(logoMenu),
      () => wpShow(menu),
      () => btnShow(btnClose),
    ];
    setTimeout(() => {
      runFunctionsInSequence(menuFunctions, time);
    }, 1400);
  }
  return (
    <button
      className={`${Style.child_menu} ${Style.btnMenu}`}
      onClick={handleClick}
      aria-label="Menu button"
    >
      <Icon icon="gg:menu-round" />
    </button>
  );
}
