import Style from "@scss/btn.module.scss";
import { scrollToTop } from "@components/GlobalFunction";

export function BtnComponent({ to, clickeable, target, onClick, styleGroup, children, id }) {
  function actionScrollToTop() {
    if (to != null && to != undefined && to != "") {
      if (clickeable) {
        if (target) {
          window.open(to, "_blank");
          return;
        } else {
          window.location.href = to;
        }
      }
    } else if (onClick) {
      onClick();
    } else {
      scrollToTop();
    }
  }

  return (
    <>
      {clickeable ? (
        <div
          className={`${Style.btn} ${styleGroup}`}
          onClick={actionScrollToTop}
        >
          <span className={Style.wpChild}>{children}</span>
          <span className={Style.loadingContainer}>
            <span className={Style.dot}></span>
            <span className={Style.dot}></span>
            <span className={Style.dot}></span>
          </span>
        </div>
      ) : (
        <a
          href={to}
          className={`${Style.btn} ${styleGroup}`}
          onClick={actionScrollToTop}
          aria-label="Button link"
          id={id}
        >
          <span className={Style.wpChild}>{children}</span>
          <span className={Style.loadingContainer}>
            <span className={Style.dot}></span>
            <span className={Style.dot}></span>
            <span className={Style.dot}></span>
          </span>
        </a>
      )}
    </>
  );
}

export function Button({ className = "", to, children }) {
  return (
    <>
      {to && (
        <a
          className={`${Style.btn} ${className}`}
          href={to}
          aria-label="Button link"
        >
          <span className={Style.wpChild}>{children}</span>
          <span className={Style.loadingContainer}>
            <span className={Style.dot}></span>
            <span className={Style.dot}></span>
            <span className={Style.dot}></span>
          </span>
        </a>
      )}
    </>
  );
}
