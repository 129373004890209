"use client";
import { isIOS, isAndroid } from "react-device-detect";
import { useApi } from "@context/ApiContext";
import StyleBtn from "@scss/btn.module.scss";

import { BtnComponent } from "@ui/general/BtnComponent";
import ClientWrapper from "@/ClienteWrapper";

export default function PayButton() {
  return (
    <ClientWrapper>
      <PayButtonComponent />
    </ClientWrapper>
  );
}

export function PayButtonComponent({ showButton = true }) {
  const { getTextoByKey } = useApi();
  const text = getTextoByKey("menu-realizar-pago")?.valor;
  return (
    <div>
      {showButton && (
        <BtnComponent
          to="/PayOnline"
          styleGroup={`ms-lg-4 py-2  ${
            isIOS || isAndroid ? StyleBtn.btn : StyleBtn.btnSecundaryv2
          } ${StyleBtn.btnSmall} ${StyleBtn.btnFlex}`}
        >
          {text}
        </BtnComponent>
      )}
    </div>
  );
}
