import React, { Fragment, useEffect, useState } from "react";
import style from "../scss/BtnChat.module.scss";
import { Icon } from "@iconify/react";
import { Link, useLocation } from "react-router-dom";
import Script from "next/script";
import { useApi } from "../context/ApiContext";
import { BtnChatItemComponent } from "./BtnChatItemComponent";
import { clickEma, hideEma } from "../utils/EmaHandler";
import StyleModal from "../scss/modal.module.scss";
import { TitleH3Component } from "./TitleH3Component";
import { getEmuFromPage } from "../api/EMUApi";
import { scrollToTop } from "./GlobalFunction";
import { isMobileOnly, isDesktop, isTablet } from "react-device-detect";
const BtnChatComponent = () => {
  const [isVisible, setIsVisible] = useState(true);

  const location = useLocation();

  const [activarChat, setActivarChat] = useState(false);
  const [showAyuda, setShowAyuda] = useState(false);
  const { getTranslation, getTextoByKey } = useApi();
  const activarOpciones = () => {
    setActivarChat(!activarChat);
    setShowAyuda(false);
  };

  const handleClickEma = () => {
    //setActivarChat(false);
    setShowAyuda(false);
    if (isMobileOnly) {
      // Redirigir a WhatsApp
      window.location.href = getTextoByKey("ema_whatsapp_movil")?.valor;
    } else {
      clickEma();
    }
  };

  const Close = () => {
    setShowAyuda(false);
  };

  const abrirModal = () => {
    setShowAyuda(!showAyuda);
  };

  const [list, setList] = useState([]);

  useEffect(() => {
    getEmuFromPage(location.pathname).then((emu) => {
      setList(emu);
    });
  }, [location.pathname]);

  const activarScroll = (url) => {
    if (url === "/Comunicate") {
      localStorage.setItem("scrollauntomatico", true);
      scrollToTop();
      // setScrollauntomatico(true);
    }
  };

  const toggleVisibility = () => {
    const footer = document.querySelector("footer"); // Asegúrate de que el footer tenga un selector identificable
    const footerOffsetTop = footer.offsetTop;
    const windowHeight = window.innerHeight;
    const scrollBottom = window.scrollY + windowHeight;

    if (scrollBottom < footerOffsetTop) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <>
      {list.length && (
        <Fragment>
          <Script
            url="https://prodesaclientes.inversionesdigitalescorp.com"
            src="https://prodesaclientes.inversionesdigitalescorp.com/assets_widget/js/iawidget.js"
            type="text/javascript"
            strategy="lazyOnload"
            onLoad={() => {
              setTimeout(() => {
                hideEma();
              }, 1000);
            }}
          />

          {activarChat ? <div className={style.containerModal}></div> : null}
          <div
            className={`${style.containerBtnchat} ${
              isVisible || activarChat ? "" : style.Notvisible
            }`}
          >
            {list.length ? (
              <div
                className={`${style.contenidoBtnchat}  ${
                  activarChat ? style.activo : ""
                } `}
              >
                <ul className="ps-0 text-center">
                  {list.map((item, index) => {
                    return (
                      <li key={index}>
                        {item?.url?.toLowerCase?.() !== "ema" ? (
                          <div
                            onClick={() => {
                              activarScroll(item.url);
                            }}
                          >
                            {getTranslation(item, "title") === "Llámanos" ? (
                              <div onClick={abrirModal}>
                                <BtnChatItemComponent item={item} />
                              </div>
                            ) : (
                              <a
                                href={item.url}
                                rel="noreferrer"
                                target={
                                  getTranslation(item, "title") ===
                                  "¡Te asesoramos!"
                                    ? "_blank"
                                    : ""
                                }
                                onClick={activarOpciones}
                              >
                                <BtnChatItemComponent item={item} />
                              </a>
                            )}
                          </div>
                        ) : (
                          <BtnChatItemComponent
                            onClick={handleClickEma}
                            item={item}
                          />
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
            ) : (
              <></>
            )}
            <div className={`${style.btnchat}`} onClick={activarOpciones}>
              <div className={`${style.iconChat}`}>
                {!activarChat ? (
                  <Icon icon="mdi:chat-processing-outline" />
                ) : null}
                {activarChat ? <Icon icon="mdi:close" /> : null}
              </div>
            </div>
          </div>

          <div
            className={`${StyleModal.modalContacto}  ${
              showAyuda ? StyleModal.showModalAyuda : ``
            }`}
          >
            <div className={`d-flex justify-content-end mb-0`}>
              <button
                onClick={Close}
                type="button"
                className={`${StyleModal.btnClose} ${StyleModal.iconClose} color-gris`}
              >
                <Icon icon={"mdi:close"} />
              </button>
            </div>
            <div>
              <TitleH3Component
                styleGroup={`color-gris fw500  mb-0 roboto letterSpacingOne text-center`}
              >
                {getTextoByKey("titulo-contacto-ema")?.valor} Líneas de atención
              </TitleH3Component>

              <TitleH3Component styleGroup="fw400 color-naranja WorkSans mb-0 text-center">
                Llámanos al {getTextoByKey("numero-contacto-ema")?.valor}{" "}
                3139040 op 1
              </TitleH3Component>
            </div>
          </div>
        </Fragment>
      )}
    </>
  );
};

export default BtnChatComponent;
