'use client';
import Style from "@scss/header.module.scss";
import { useState } from "react";
import { Icon } from "@iconify/react";
import ClientWrapper from "@/ClienteWrapper";

export default function MenuRound() {
  return (
    <ClientWrapper>
      <SearchFormComponent />
    </ClientWrapper>
  );
}

export function SearchFormComponent() {
  const [buscar, setBuscar] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const search = (e) => {
    e.preventDefault();
    setBuscar(false);
    const searchParams = new URLSearchParams();
    searchParams.set("search", searchValue);
    if (searchValue.length > 0) {
      const queryString = searchParams.toString();
      window.location.href = `/ResultadosBuscador?${queryString}`;
    }
  };
  const buscaropcion = () => {
    setBuscar(true);
  };

  return (
    <>
      <form
        className={`mb-0 mb-md-0 me-md-2 me-lg-0 ${Style.searcher} ${
          buscar ? Style.active : ""
        } class-formBuscar`}
        onSubmit={search}
        id="id-formBuscar"
      >
        <button
          className={`${Style.btnSearch} class-btnBuscar`}
          type="submit"
          id="id-btnBuscar"
          aria-label="Search button"
        >
          <Icon icon="bi:search" />
        </button>
        <input
          className={`${Style.input} class-inputBuscar`}
          type="search"
          onChange={(e) => setSearchValue(e.target.value)}
          value={searchValue}
          placeholder="Buscar"
          aria-label="Search"
          id="id-inputBuscar"
        />
      </form>
      <button
        className={`${Style.btnSearchactive} class-btnBuscar ${
          !buscar ? "d-block" : "d-none"
        }`}
        type="button"
        id="id-btnBuscar"
        onClick={buscaropcion}
        aria-label="Search button"
      >
        <Icon icon="bi:search" />
      </button>
    </>
  );
}
