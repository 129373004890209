import styles from "@/scss/header.module.scss";

export type MenuItemType = {
    id: number;
    link?: string;
    text?: string;
}

export default function MenuItem({ data: { link, text }, internal = false, className, target = '_self' }: { data: MenuItemType, internal?: boolean, className?: string, target?: '_self' | '_blank' }) {
    const LinkItem = internal ? <a href={`${link}`} className={`${styles.subNavItem} ${className ?? ''}`} arial-label="Menu item">{text}</a> : <a href={link} className={`${styles.subNavItem} ${className ?? ''}`} target={target} arial-label="Menu item">{text}</a>
    return (
        <li>
            {LinkItem}
        </li>
    );
}